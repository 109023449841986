<template>
  <div class='list'>
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="enable" clearable placeholder="启用状态" @change="onSearch" style="margin-right: 20px;">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="status" clearable placeholder="审核状态" @change="onSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="search" placeholder="请输入商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button class="btn" type="primary" @click="onAdd">新增</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width='100'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="店铺logo">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.logoUrl)">查看图片</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150" prop="companyName" label="公司名称" show-overflow-tooltip ></el-table-column>
          <el-table-column min-width="150" prop="shopName" label="店铺名称" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" prop="contactTel" label="电话号码" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="150" prop="address" label="商超地址" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.area }}{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column  min-width="150" prop="schoolName" label="关联学校" show-overflow-tooltip></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)" :disabled="scope.row.status == 0 || scope.row.status == 2"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <span :style="scope.row.status == 0 ? 'color:red' : ''">{{ scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '审核通过' : '审核不通过' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="300">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="primary" size="small" @click="onWithdrawal(scope.row)">提现</el-button> -->
              <el-button class="btn" type="danger" size="small" plain @click="handleDel(scope.row, 0)" v-if="scope.row.status == 0">撤销</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="handleEdit(scope.row)" v-if="scope.row.status == 1">编辑</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)">查看</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDel(scope.row, 2)" v-if="scope.row.status == 2">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
    <el-image-viewer v-if="showViewer" :on-close="() => { showViewer = false }" :url-list="imgList"></el-image-viewer>
    <!-- <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="支付宝真实姓名" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付宝账号" prop="number">
              <el-input v-model="ruleForm.number" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>

export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {

      dialogVisible: false,
      submitLoding: false,
      ruleForm: {
        name: '',
        number: '',
      },
      rules: {
        name: [{ required: true, message: '请输入支付宝真实姓名', trigger: 'submit' }, {
          validator: function (rule, value, callback) {
            if (/^[\u4e00-\u9fa5]+$/.test(value) == false) {
              callback(new Error("请输入支付宝真实姓名"));
            } else {
              //校验通过
              callback();
            }
          },
          trigger: "blur"
        }],
        number: [{ required: true, message: '请输入支付宝账号', trigger: 'submit' },
        {
          validator: function (rule, value, callback) {
            if (/^\w{3,20}$/.test(value) == false) {
              callback(new Error("请输入邮箱或支付宝账号或手机号"));
            } else {
              //校验通过
              callback();
            }
          },
          trigger: "blur"
        }
        ],
      },
      loading: false,
      search: '',
      tableData: [
        // {
        //   businessLicenseUrl: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        // }
      ],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '1',
        label: '审核通过'
      }, {
        value: '2',
        label: '审核不通过'
      }],
      options1: [{
        value: '0',
        label: '否'
      }, {
        value: '1',
        label: '是'
      }],
      status: '',
      enable: '',
      // 图片预览
      showViewer: false,
      imgList: []
    }
  },
  created () {
    this.getShopList()
  },
  methods: {
    // 商超列表
    getShopList () {
      this.loading = true;
      this.$axios.get(this.$api.shopList, {
        params: {
          shopName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,
          status: this.status, // 审核状态 ( 0:待审核 1:审核通过 2:审核不通过 )
          startStatus: this.enable
        },
      }).then((res) => {
        let list = res.data.result.list.map((e) => {
          return {
            ...e,
            switch: e.startStatus == "1",
          };
        });
        this.tableData = list;
        this.totalItemsCount = res.data.result.totalCount;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    onAdd () {
      // console.log('新增');
      this.$router.push({ path: '/shopSetup/detail/add' });
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getShopList()
    },
    // 切换状态
    changeStatus (row) {
      console.log(row.switch);
      let item = Object.assign({}, row)
      const originalSwitch = item.switch;
      this.$confirm(`该商超即将${item.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$axios.put(this.$api.shopStatus, {
          shopId: item.shopId,
          startStatus: item.switch ? "1" : "0",
          // status: item.switch ? "1" : "3",
        }).then(() => {
          this.$set(item, "switch", originalSwitch);
          this.$message.success("操作成功");
        }).catch(() => {
          this.$set(item, "switch", !originalSwitch);
          this.$message.error("操作失败");
        });
      }).catch(() => {
        this.$set(item, "switch", !originalSwitch);
      });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e;
      this.getShopList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e;
      this.getShopList();
    },
    // 查看
    showDetail (row) {
      this.$router.push({ path: '/shopSetup/detail/info', query: { id: row.shopId }, });
    },

    // 提现
    onWithdrawal () {
      this.dialogVisible = true
    },
    // 编辑
    handleEdit (row) {
      this.$router.push({ path: '/shopSetup/detail/edit', query: { id: row.shopId }, });
    },
    // 删除or撤销
    handleDel (row, e) {
      let titel = '';
      if (e == 0) {
        titel = '撤销';
      } else {
        titel = '删除';
      }

      this.$confirm(`此操作将永久${titel}该内容, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(this.$api.shopDelete + row.shopId).then((res) => {
          if (res.data.code == 100) {
            this.$message.success(titel + '成功')
          }
          this.getShopList()
        })
      }).catch(() => { });
    },
    // 图片预览
    previewImage (url) {
      console.log(this.imgList);
      this.imgList[0] = url
      this.showViewer = true
    },
    // 关闭对话框
    onCancel () {
      this.resetForm()
      this.dialogVisible = false
    },
    // 重置表单
    resetForm () {
      this.ruleForm = {
        number: this.ruleForm.number,
        name: this.ruleForm.name,
      }
      this.$refs.ruleForm.resetFields();
    },
    // 提交表单
    // submitForm(formName) {
    //   let param = {
    //     contractType: this.ruleForm.contractType,
    //     templateName: this.ruleForm.templateName,
    //     fileId: this.fileId,
    //     fileUrl: this.fileUrl,
    //   }
    //   this.submitLoding = true
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       this.$axios.post(this.$api.uploadTemplate, param).then((res) => {
    //         if (res.data.code == 100) {
    //           this.$message.success('添加成功');
    //         }
    //         this.getShopList();
    //         this.submitLoding = false
    //         this.dialogVisible = false;
    //         this.resetForm();
    //       });
    //     } else {
    //       this.submitLoding = false
    //       this.$message.error('提交失败');
    //       return false;
    //     }
    //   });
    // },
  },
}
</script>

<style scoped lang='scss'>
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      margin-left: 20px;
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>